import React, { useState } from 'react'

const Whiskey = () => {
    const [liquors, setLiquors] = useState([]);

    async function getLiquors() {
        try {
            const res = await fetch(`https://assets.dougkarda.com/data/liquor-inventory.json`);
            const liquors = await res.json();
            setLiquors(liquors);
        } catch (err) {
            console.error(err);
        }
    }

    return (
        <>
            <div id="whiskey">
            <ul>
                {liquors.map((liquor, index) => (
                    <li key={index}>{liquor.title}</li>
                ))}
            </ul>
            </div>
        </>
    )
}

export default Whiskey;