import React from 'react'

const StarWarsWatchList = () => {

    async function getLiquor() {
        try {
            const res = await fetch(`https://assets.dougkarda.com/data/starwars-watchlist.json`);
            const liquor = await res.json();
        } catch (err) {
            console.error(err);
        }
    }

    return ( 
        <>
            <div id="star-wars-watch-list">
                <a href="">Jedi</a>
                <a href="">Rebels</a>
                <a href="">Mando</a>
                <a href="">Boba Fett</a>
            </div>
        </>
    )
}
  
export default StarWarsWatchList;