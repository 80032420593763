import React from 'react'

const TV = () => {
    return ( 
        <>
            <div id="tv">
                <a href="">Bacardi</a>
                <a href="">Hendrix</a>
            </div>
        </>
    )
}
  
export default TV;