import React from 'react'

const Liquor = () => {
    return ( 
        <>
            <div id="liquor">
                <a href="">Bacardi</a>
                <a href="">Hendrix</a>
            </div>
        </>
    )
}
  
export default Liquor;