import logo from './logo.svg'
import './App.css'
import Navbar from './Navbar'
import Home from './Components/Home'
import Whiskey from './Components/Whiskey'
import Liquor from './Components/Liquor'
import Movies from './Components/Movies'
import TV from './Components/Tv'
import StarWarsWatchList from './Components/StarWarsWatchList'

function App() {

    let Component
    switch (window.location.pathname) {
        case "/":
            Component = Home
            break
        case "/whiskey":
            Component = Whiskey
            break
        case "/liquor":
            Component = Liquor
            break
        case "/movies":
            Component = Movies
            break
        case "/tv":
            Component = TV
            break
        case "/starwarswatchlist":
            Component = StarWarsWatchList
            break
    }

    return (
        <>
            <Navbar />
            <Component />
        </> 
    )
}

export default App;
