import React from 'react'

const Navbar = () => {
    return ( 
        <>
            <nav className="nav">
                <a href="/" className="site-title">Home</a>
                <ul>
                    <li>
                        <a href="/whiskey">Whiskey</a>
                    </li>
                    <li>
                        <a href="/liquor">Liquor</a>
                    </li>
                    <li>
                        <a href="/movies">Movies</a>
                    </li>
                    <li>
                        <a href="/tv">TV</a>
                    </li>
                    <li>
                        <a href="/starwarswatchlist">Star Wars Watch List</a>
                    </li>
                </ul>
            </nav>
        </>
    )
}
  
export default Navbar;