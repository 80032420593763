import React from 'react'

const Home = () => {
    return ( 
        <>
            <div id="home">
                <h2>Home page stuff</h2>
            </div>
        </>
    )
}
  
export default Home;